import React from 'react';
import { useState } from 'react';

const CheckboxList = ({ title, items, onFilterChange }) => {
    
    const initialCheckedState = items.reduce((state, item) => {
      state[item] = true;
      return state;
    }, {});
    const [checkedItems, setCheckedItems] = useState(initialCheckedState);
  
    const handleCheckboxChange = (event) => {
      const itemName = event.target.name;
      setCheckedItems({
        ...checkedItems,
        [itemName]: event.target.checked,
      });
  
      // Call the provided onFilterChange function with the new state of checked items
      onFilterChange({
        ...checkedItems,
        [itemName]: event.target.checked,
      });
    };

    return (
      <div className=' text-black p-6 font-normal text-lg'>
        <h2>{title}</h2>
        {items.map((item, index) => (
          <div className='flex flex-row items-center'>
            <input
              type="checkbox"
              name={item} // Make sure the 'name' corresponds to what you will check in the records
              className="h-4 w-4 rounded form-checkbox checked:text-blue-600 mr-2"
              checked={checkedItems[item] || false}
              onChange={handleCheckboxChange}
            />
            <label key={index} className="block">
              {item}
            </label>
          </div>
        ))}
      </div>
    );
  };

export default CheckboxList;
