import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MainPage from './components/MainPage';
import ErrorPage from './components/ErrorPage';
import Modal from './components/Modal';
//import Blog from './components/Blog';
/*
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';

const client = new ApolloClient({
  uri: 'https://api-ap-southeast-2.hygraph.com/v2/clptj63pp3cgl01us56oycj91/master',
  cache: new InMemoryCache(),
  headers: {
    'Authorization': `Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6ImdjbXMtbWFpbi1wcm9kdWN0aW9uIn0.eyJ2ZXJzaW9uIjozLCJpYXQiOjE3MDE4NTQ4OTAsImF1ZCI6WyJodHRwczovL2FwaS1hcC1zb3V0aGVhc3QtMi5oeWdyYXBoLmNvbS92Mi9jbHB0ajYzcHAzY2dsMDF1czU2b3ljajkxL21hc3RlciIsIm1hbmFnZW1lbnQtbmV4dC5ncmFwaGNtcy5jb20iXSwiaXNzIjoiaHR0cHM6Ly9tYW5hZ2VtZW50LmdyYXBoY21zLmNvbS8iLCJzdWIiOiI1Y2I3NmE3ZC0xZWJmLTQ0MDktOWVkNS01ODc0MjIyMTQ2OTgiLCJqdGkiOiJjbHB0a2dscm4zZ25uMDF1bjE5c2NhaDdqIn0.FEPbejoaG8ngn_Jbk45ox7koSci6n0wHGy2Lf6LC1SrxsZ2nT_G35UpbNeguU3_SUMjZrpsuKJ6grsJS5qabHm7L8POq_iWKXjTzKXI5CH9_RsVKEh9vyL4hYCP0LiukH6esSzEJ8l1pnGNCDcepjPanas3UIayTOAHt-DYqEy67xzd6rGorePF3VzVZrNXjLums3nqfqwwCvCXIS18k9e0eemd6t1jg4DY9oSdI5U80t-dIHqfY7yKOPYmbr_74wtfVYeWA10a9jBJCjCOLT2Z1IxB90HiGTt42X6omqOFLS-mpFpSyfpiOuoVMLiY3Yg2tkuvxnISSuQYHYEjhTrFNtZLNSYsNP7KFgaq-gZXzedVn0Z4JlpcvUpzQLmEDDIHEFS3wACNJDm19BgUvAduH6V7DJveoB6nqXCHhnf8cBbn23ep7qRkx8-ZqQprB6_ecHYS4qU-r27G2uKyUOHA6EpIB5Iq2Nf5y8zYtD160K-cTKuAhQLHni45ylAAN8eQnRonlotSHrdzOxGDCv2efTEJVwL8rVlDvOpelq2sFE9TX3X5ufHf0lCb9JHeoa66Mm1mjtxwFoRndgVPG08NWmLFOlKQn2u6M-Ba_7X7llIC8KWhuxhXWu5P5MB5vohi2vMaJi4szmaBXpWytuU3XoGZZr-tdxeX2c8o9yic`
  }
});
*/

function App() {
  return (
    //<ApolloProvider client={client}>
      <Router>
        <Routes>
          <Route exact path="/" element={<MainPage />} >
            <Route path="/experiment/:experimentUrl" element={<Modal />} />
          </Route>
          {/*<Route path="/blog/:blogUrl" element={<Blog />} />*/}
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </Router>
    //</ApolloProvider>
  );
}

export default App;
