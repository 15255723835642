import React from 'react';

const Tag = ({ text }) => {
  return (
    <div className={`mb-2 inline-block bg-gray-50 text-gray-600 ring-1 ring-inset ring-gray-500/10 text-sm font-medium px-4 py-1 rounded-full`}>
      {text}
    </div>
  );
};

export default Tag;