export default function ErrorPage() {
  return (
    <div className="flex flex-col justify-center items-center h-screen">
      <p className="text-black text-3xl font-medium">
        Sorry, we couldn’t find that page.
      </p>
      <br></br>
      <p className="text-black text-xl text-center w-6/12">
      If you double-check it, we’re happy to try again.
      </p>
    </div>
  );
}
