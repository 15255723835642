import React from 'react';

const IconCircle = ({ icon: Icon, size = 'w-6 h-6' }) => {
  return (
    <div className={`inline-flex items-center justify-center rounded-full bg-blue-100 ${size}`}>
      {Icon && <Icon className="text-white" />}
    </div>
  );
};

export default IconCircle;