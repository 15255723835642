import React, { useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Tag from './Tag';
import { useOutletContext } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const Modal = () => {
  const modalRef = useRef();
  const context = useOutletContext();
  const navigate = useNavigate();
  const { experimentUrl } = useParams();

  const selectedCard = context.records.find(record => record.fields['Experiment URL'] === experimentUrl);

  const getContentForCard = () => {
    return(
    <div>
        <Helmet>
          <title>{selectedCard.fields['Experiment Name']} | Growth Experiments</title>
          <meta name="description" content={selectedCard.fields['Customer Journey']} />
        </Helmet>
        <h2 className='text-2xl font-bold text-gray-800 text-center'>{selectedCard.fields['Experiment Name']}</h2>
          <div className='py-4'>
            <p className='uppercase text-xs font-bold'>Business Models</p> 
            <div className='flex flex-row'>
            {selectedCard.fields['Business Models'].map((model, index) => (
              <div className='pr-1 pt-1' key={index}> 
                <Tag key={index} text={model}/>
              </div>
            ))}
            </div>
          </div>
          <div className='flex flex-row w-full'>
            <div className='py-3 w-1/2'>
              <p className='uppercase text-xs font-bold'>Funnel Stage</p>
              <div>{selectedCard.fields['Funnel Stage']}</div>
            </div>
            <div className='py-3 w-1/2'>
              <p className='uppercase text-xs font-bold'>Product Touchpoint</p>
              <div>{selectedCard.fields['Product Touchpoint']}</div>
            </div>
          </div>
          <div className='py-3'>
            <p className='font-medium'>Customer Experience</p>
            <div>{selectedCard.fields['Customer Journey']}</div>
          </div>
          <div className='py-3'>
            <p className='font-medium'>When It Works Best</p>
            <div>{selectedCard.fields['When It Works']}</div>
          </div>
    </div>)
  };


  // Close modal on click outside
  useEffect(() => {
    const closeModal = () => {
        navigate('/');
      };

    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
});

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
      <div ref={modalRef} className="relative top-20 mx-auto p-5 border sm:w-full md:w-1/2 shadow-lg rounded-md bg-white">
        <div>{getContentForCard()}</div>
      </div>
    </div>
  );
};

export default Modal;
