import React from 'react';
import CheckboxList from './CheckboxList';
import Section from './Section'
import useAirtable from '../hooks/useAirtable';
import { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function MainPage() {
    const baseId = 'appiiTRZl5tWoL37e';
    const tableName = 'tblphhkCmTtrM9bvz';

    const { records, loading, error } = useAirtable(baseId, tableName);
    const [filteredRecords, setFilteredRecords] = useState(records);
    const [businessModelFilters, setBusinessModelFilters] = useState({});
    const [funnelStageFilters, setFunnelStageFilters] = useState({});
    const [productTouchpointFilters, setProductTouchpointFilters] = useState({});

    useEffect(() => {
        const filtered = records.filter(record => {
            const businessModelMatch = Object.keys(businessModelFilters).length === 0 || 
                Object.entries(businessModelFilters).some(([key, value]) => value && record.fields['Business Models'].includes(key));
            const funnelStageMatch = Object.keys(funnelStageFilters).length === 0 || 
                Object.entries(funnelStageFilters).some(([key, value]) => value && record.fields['Funnel Stage'] === key);
            const productTouchpointMatch = Object.keys(productTouchpointFilters).length === 0 || 
                Object.entries(productTouchpointFilters).some(([key, value]) => value && record.fields['Product Touchpoint'].includes(key));
            
            return businessModelMatch && funnelStageMatch && productTouchpointMatch;
            });
    
        setFilteredRecords(filtered);
    }, [records, businessModelFilters, funnelStageFilters, productTouchpointFilters]);
    
    // handleFilterChange now needs to know which category it's handling
    const handleFilterChange = (category, checkedItems) => {
        if (category === 'Business Models') {
        setBusinessModelFilters(checkedItems);
        } else if (category === 'Funnel Stage') {
        setFunnelStageFilters(checkedItems);
        } else if (category === 'Product Touchpoint') {
        setProductTouchpointFilters(checkedItems);
        }
    };

    /*
    if (loading) return (
        <div className='flex justify-center items-center w-full h-screen'>
            <img src='/emptyState.png' alt='Search Icon' className='w-64 h-auto'></img>
        </div>
        );
    
    if (error) return <div>Error: {error.message}</div>;
    if (!records) return <div>No data available</div>;
    */

    return(
            <div className="flex flex-row mr-auto h-screen">
                <Helmet>
                    <title>Growth Experiment Vault</title>
                    <meta name="description" content="50+ growth experiments to boost users & revenue"/>
                </Helmet>
                <div className="hidden md:block flex-shrink-0 h-screen w-[250px] overflow-x-hidden bg-gray-100 fixed">
                    <CheckboxList
                    title="Business Model" items={['SaaS', 'Marketplace', 'Ecommerce']}
                    onFilterChange={(checkedItems) => handleFilterChange('Business Models', checkedItems)} />
                    <CheckboxList title="Funnel Stage" items={['Acquisition', 'Activation', 'Monetisation','Retention']} 
                    onFilterChange={(checkedItems) => handleFilterChange('Funnel Stage', checkedItems)} />
                    <CheckboxList title="Product Touchpoint" items={['Onboarding','Forms & listings','Mobile app','Pricing','Checkout','Marketing website','Landing pages','Emails','Marketplace supply','Miscellaneous']} 
                    onFilterChange={(checkedItems) => handleFilterChange('Product Touchpoint', checkedItems)} />
                </div>
                <div className='flex flex-col w-full pl-4 md:pl-[270px] pr-4 pt-10 pb-4'>
                    <h2 className="text-2xl font-bold mb-4">ExperimentVault</h2>
                    {loading || error ?
                        <div className='flex flex-wrap w-full -mx-2'>
                            <div className="flex-grow  w-full sm:w-1/3 md:w-1/4 lg:w-1/5 bg-gray-200 p-2 mx-2 my-4 rounded-lg shadow-md h-[270px]"></div>
                            <div className="flex-grow  w-full sm:w-1/3 md:w-1/4 lg:w-1/5 bg-gray-200 p-2 mx-2 my-4 rounded-lg shadow-md h-[270px]"></div>
                            <div className="flex-grow  w-full sm:w-1/3 md:w-1/4 lg:w-1/5 bg-gray-200 p-2 mx-2 my-4 rounded-lg shadow-md h-[270px]"></div>
                            <div className="flex-grow  w-full sm:w-1/3 md:w-1/4 lg:w-1/5 bg-gray-200 p-2 mx-2 my-4 rounded-lg shadow-md h-[270px]"></div>
                            <div className="flex-grow  w-full sm:w-1/3 md:w-1/4 lg:w-1/5 bg-gray-200 p-2 mx-2 my-4 rounded-lg shadow-md h-[270px]"></div>
                            <div className="flex-grow  w-full sm:w-1/3 md:w-1/4 lg:w-1/5 bg-gray-200 p-2 mx-2 my-4 rounded-lg shadow-md h-[270px]"></div>
                            <div className="flex-grow  w-full sm:w-1/3 md:w-1/4 lg:w-1/5 bg-gray-200 p-2 mx-2 my-4 rounded-lg shadow-md h-[270px]"></div>
                            <div className="flex-grow  w-full sm:w-1/3 md:w-1/4 lg:w-1/5 bg-gray-200 p-2 mx-2 my-4 rounded-lg shadow-md h-[270px]"></div>
                        </div>
                    : <Section title="Experiment Vault" data={filteredRecords} /> 
                }
                </div>
                <Outlet context={{ records }}/>
            </div>
        )
}

export default MainPage