import { useQuery } from 'react-query';

const fetchAirtableData = async (baseId, tableName) => {
  const response = await fetch(`https://api.airtable.com/v0/${baseId}/${tableName}`, {
    headers: {
      Authorization: `Bearer ${process.env.REACT_APP_AIRTABLE_API_KEY}`,
    },
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return response.json();
};

const useAirtable = (baseId, tableName) => {
  const { data, isLoading, error } = useQuery(
    ['airtable', baseId, tableName],
    () => fetchAirtableData(baseId, tableName),
    {
      // You can add options here, like refetch intervals, stale time, etc.
    }
  );

  return {
    records: data ? data.records : [],
    loading: isLoading,
    error,
  };
};

export default useAirtable;
