import React from 'react';
import Tag from './Tag';
import { CartIcon, DollarIcon, MobileIcon, MailIcon, MegaphoneIcon, QuestionIcon, ClickIcon } from './HeroIcons';
import IconCircle from './IconCircle';

const Card = ({ experiment, onClick }) => {

  const iconMap = {
    'Checkout': CartIcon,
    'Pricing': DollarIcon,
    'Onboarding': MegaphoneIcon,
    'Forms & listings': CartIcon,
    'Mobile app': MobileIcon,
    'Marketing website': MegaphoneIcon,
    'Landing pages': MegaphoneIcon,
    'Marketplace supply': CartIcon,
    'SEO content': ClickIcon,
    'Reviews': MegaphoneIcon,
    'Emails': MailIcon,
    'Pop-ups': MegaphoneIcon,
    'Miscellaneous': QuestionIcon
  };

  const IconComponent = iconMap[experiment['Product Touchpoint']] || null;

  return (
    <div className="transition duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg max-w-sm rounded overflow-hidden shadow-lg outline outline-1 outline-gray-100 bg-white h-full" onClick={onClick}>
      <div>
          <div className='flex flex-col items-start px-5 mt-6 mb-3'>
            <IconCircle icon={IconComponent} size="w-10 h-10"/>
            <div className="text-lg mb-1 py-2 font-medium line-clamp-2">{experiment['Experiment Name']}</div>
            <Tag text={experiment['Funnel Stage']}/>
            <div className="text-md my-1 line-clamp-3">{experiment['Customer Journey']}</div>
          </div>
        </div>
    </div>
  );
};

export default Card;