import React from 'react';
import { useNavigate } from 'react-router-dom';
import Card from './Card'


const Section = ({ data = [] }) => {
  const navigate = useNavigate();

  const sortByNestedProperty = (parentProperty, childProperty) => {
    return (a, b) => {
      if (!a[parentProperty] || !b[parentProperty]) return 0;
      
      let valueA = a[parentProperty][childProperty];
      let valueB = b[parentProperty][childProperty];
  
      // Assuming the values are strings, adjust if they are numbers or other types
      valueA = typeof valueA === 'string' ? valueA.toUpperCase() : valueA;
      valueB = typeof valueB === 'string' ? valueB.toUpperCase() : valueB;
  
      return valueA < valueB ? -1 : (valueA > valueB ? 1 : 0);
    };
  };

  const openModal = (experimentUrl) => {
    navigate(`/experiment/${experimentUrl}`);
  };

  return (
      <div className="flex flex-wrap -mx-2">
        {[...data]
        .sort(sortByNestedProperty('fields', 'Experiment Name'))
        .map((record, index) => {
          if (!record || !record.fields) {
            console.error('Invalid record:', record);
            return null;
          }
          return (
            <div key={index} className="px-2 py-4 w-full sm:w-1/3 md:w-1/4 lg:1/5">
              <Card key={index} experiment={record.fields} onClick={() => openModal(record.fields['Experiment URL'])} />
            </div>
          )
        })}
      </div>
  );
};

export default Section;
